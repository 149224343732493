<template>
  <div class="course-details">
    <div class="cd-info">
      <vx-card class="mt-base">
        <div class="vx-row">
          <div class="vx-col w-full lg:w-1/2 md:pr-5">
            <img
              :src="courseFeed.imageURL"
              alt="content-img"
              class="responsive rounded-lg cd-image"
            />
          </div>
          <div class="vx-col w-full lg:w-1/2">
            <div class="mt-3 md:mt-base">
              
              <!-- <div class="flex justify-end">
                <div class="reviews-ratings mt-5 mb-5">
                  <div class="d-flex">
                    <div class="d-flex text-warning">
                      <span class="mr-1">4.5</span>
                      <feather-icon icon="StarIcon" svgClasses="h-4 w-4" />
                    </div>
                    <span class="ml-2">(60 reviews)</span>
                  </div>
                </div>  
                
              </div> -->
              <h3>{{ courseFeed.title }}</h3>
              <p class="mt-5 lead">
                {{ courseFeed.headline }}
              </p>
              <div class="mt-4">
                <p class="font-semibold lead">Topics:</p>
                <div class="flex flex-wrap mt-2">
                  <div v-for="(topic, index) in courseFeed.topics" :key="index" class="rounded-full bg-custom-light px-3 py-2 mr-3">
                    <span class="">{{ topic }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-5 lg:flex justify-between" >
                <div class="" v-if="courseFeed.type === 'Private'">
                  <span class="font-semibold text-success h4">${{ courseFeed.discountPrice }}</span>
                  <span
                    class="text-primary ml-2 h4"
                    style="text-decoration: line-through"
                    >${{ courseFeed.price }}</span>
                </div>
                <div class="" v-if="courseFeed.type === 'Public'">
                  <span class="font-semibold text-primary h5">Free</span>
                </div><br>
              </div>
              <div class="mt-5 md:flex items-center justify-between">
                <vs-button
                  v-if="isCourseFeedSubscribedByUser === false"
                  @click="joinCourseFeed"
                  color="primary"
                  type="filled"
                  >Join Now</vs-button
                >
                <vs-button
                  v-if="isCourseFeedSubscribedByUser === true"
                  color="success"
                  type="filled"
                  @click="visitCourseFeed"
                  >Visit</vs-button
                >
                <div class="d-flex items-center mt-base md:mt-0">
                  <span class="font-semibold mr-3">SHARE:</span>
                  <div class="share-network-list">
                  <ShareNetwork
    v-for="network in networks"
    :network="network.network"
    :key="network.network"
    :style="{backgroundColor: network.color}"
    :url="url"
    :title="sharing.title"
    :description="sharing.description"
    :quote="sharing.quote"
    :hashtags="sharing.hashtags"
    :twitterUser="sharing.twitterUser"
  >
    <feather-icon :icon="network.icon"></feather-icon>
  </ShareNetwork>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>

    <div class="">
      <div class="vx-row">
        <div class="vx-col w-full sm:w-2/3 md:w-full lg:w-2/3 mt-base">
          <!-- <vx-card> -->
            <!-- <div class=""> -->
              <!-- <h5>More about the group</h5> -->
              <!-- <hr /> -->
            <!-- </div> -->
            <!-- <div v-html="courseFeed.description" class="mt-5 about-coursefeeds"> -->
            <!-- {{ courseFeed.description }} -->
            <!-- {{ courseFeed.description }} -->
            <!-- </div> -->
          <!-- </vx-card> -->


          <!-- <vx-card class="mt-base">
            <div class="">
              <div class="flex justify-between">
                <h5 class="mb-0">Reviews</h5>
                <vs-button
                  @click="show_pop_up = true"
                  color="primary"
                  type="filled"
                  size="small"
                  >Add Review</vs-button
                >
              </div>
    

              <div id="lms">
                <vs-popup title="Write a review" :active.sync="show_pop_up">
                  <div class="p-4">
                    <review-pop-up />
                  </div>
                </vs-popup>
              </div>
            </div>
          </vx-card> -->
        </div>

        <div class="vx-col w-full sm:w-1/3 md:w-full lg:w-1/3 mt-base">
          <vx-card class="">
            <div class="flex justify-between">
              <h5>Listed By</h5>
              <div class="">
                    <vs-button
              color="primary"
              icon-pack="feather"
              icon="icon-message-square"
              class=""
              size="small"
              @click="goToChatRoom()"
              >Message Me</vs-button
            >
              </div>
            </div> 
            <hr />
            <div class="vx-row mt-5">
              <div class="vx-col w-full md:w-1/4 lg:w-1/4">
                <img
                  :src="courseFeed.userRef.photoURL"
                  alt="content-img"
                  class="responsive rounded-lg"
                 />
              </div>
              <div class="vx-col w-full md:w-3/4 lg:w-3/4 mt-3 md:mt-0">
                <div class="mb-3">
                <h5><a @click="bindUrl(courseFeed.userRef)" class="cursor-pointer text-primary">{{courseFeed.userRef.displayName}}</a></h5>
                <!--  <h5 class="mb-1">{{ courseFeed.userRef.displayName }}</h5>-->
                <!--  <span class="text-grey">Educator</span> -->
                </div>
                <div class="">
                  <p>
                    {{ courseFeed.userRef.about }}
                  </p>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    <!-- Book now Course popup -->
    <div class="">
      <vs-popup
        class="holamundo"
        title="Join our community"
        :active.sync="booknowCourse"
      >

        <div class="px-4 py-2">
          <div class="vx-row mb-5">
            <div class="vx-col w-full">
              <h4 class="mb-2">One step more to become part of our community</h4>
              <p>Fill your payment details to complete onboarding process.</p>
            </div>
          </div>
 <div class="vx-row">
            <div class="vx-col w-full lg:w-3/5">
              <vx-card class="mb-base">
                <div class="">
                  <h5>Payment Options</h5>
                  <p class="text-grey">
                    Be sure to click on correct payment option
                  </p>
                </div>
                <div class="mt-3">
                  <ul>
                    <li>
                    <!-- CARD DETAILS -->
                    <div v-for="item in card_list" :key="item.id">
                      <div class="flex flex-wrap justify-between items-center">
                        <vs-radio v-model="paymentMethod" :vs-value="item.id">
                          <div class="flex items-center">
                            <img
                              src="@/assets/images/pages/bank.png"
                              alt="bank-logo"
                              height="40"
                              width="50"
                              class="inline-flex"
                            />
                            <span>{{ item.brand }}</span>
                            <span class="font-medium text-grey ml-2"
                              >ending in {{ item.last4 }}</span
                            >
                          </div>
                        </vs-radio>
                        <!-- <span>John Doe</span> -->
                        <span>{{ item.exp_month }}/{{ item.exp_year }}</span>
                      </div>
                    </div>
                    <div v-if="card_list.length == 0">
                      <div class="not-data-table vs-table--not-data">
                        No saved cards to show.
                      </div>
                    </div>
                     <h6 class="mt-3" style="color: rgb(184, 194, 204);">
                    Another payment method
                  </h6>
                  <vs-divider class="my-6" />
                  </li>
                     <li>
                    <!-- vs-value="credit-debit-atmCard" -->
                    <vs-radio v-model="paymentMethod" vs-value="newcard"
                      >Add credit/debit card</vs-radio
                    >
                    <span class="row" >
                      <span style="font-size: 13px;" class="col-10 ml-2 mb-3"
                        >We’ll save this card for your convenience. Remove it by
                        going to Your Account section.</span
                      >
                    </span>
                     <stripe-element-card
                        ref="stripeRef"
                        :pk="publishableKey"
                        @token="tokenCreated"
                      />
                  </li>
                   <!-- OPTION 1 -->
                  
                  </ul>
                </div>
              </vx-card>
            </div>

            <div class="vx-col w-full lg:w-2/5">
              <vx-card>
                <p class="text-grey mb-3">Coupons</p>
                <div class="flex items-center justify-between">
                  <vs-input
                    class="inputx"
                    size="small"
                    placeholder=""
                  />
                  <span class="font-medium text-primary cursor-pointer ml-3"
                    >Apply</span
                  >
                </div>
                <vs-divider />
                <p class="font-semibold mb-3">Price Details</p>
                <div class="flex justify-between mb-2">
                  <span class="text-grey">Total Price</span>
                  <span>${{ getTotalPrice(courseFeed.price) }}</span>
                </div>
                <div class="flex justify-between mb-2">
                  <span class="text-grey">Discount</span>
                  <span class="text-success">-${{ getDiscount(courseFeed.price ,courseFeed.discountPrice) }}</span>
                </div>
                <!-- <div class="flex justify-between mb-2">
                  <span class="text-grey">GST</span>
                  <span>${{ getGst(courseFeed.price) }}</span>
                </div> -->
                <vs-divider />

                <div class="flex justify-between font-semibold mb-3">
                  <span class="h5 font-semibold mb-0">Total</span>
                  <span class="h5 font-semibold mb-0">${{ getTotal() }}</span>
                </div>
                <vs-button @click="submit();" class="w-full font-semibold" color="success">Continue to checkout</vs-button>
              </vx-card>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>

   
  </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import ReviewPopUp from "@/layouts/components/coursepal/ReviewPopUp";

import { API, graphqlOperation } from '@aws-amplify/api'
import { getCourseFeed, courseFeedSubscriptionByUser } from '@/graphql/queries'
import { createCourseFeedSubscriber } from '@/graphql/mutations'
import { Storage } from '@aws-amplify/storage'
import { createErrorObject, createSuccessObject } from '@/utils'

import VueSocialSharing from 'vue-social-sharing'
export default {
	name: 'CourseDetails',
  data() {
    return {
      addReview: false,
      show_pop_up: false,
      booknowCourse: false,
      publishableKey: process.env.VUE_APP_STRIPE_API_KEY,
      token: null,
      charge: null,
      amount:1000,
      paymentMethod : 'newcard',
      card_list : [],
      options: [
        { id: 1, label: "5 stars" },
        { id: 2, label: "4 stars" },
        { id: 3, label: "3 stars" },
        { id: 4, label: "2 stars" },
        { id: 5, label: "1 stars" },
      ],
         url: 'https://coursepal.app/',
       sharing: {
       // url: 'https://coursepal.app/',
        title: 'Join Learning Groups at Coursepal!',
        description: 'Join Coursepal to meet like-minded people & learn together!',
        quote: 'Join Learning Groups at COursepal!',
        hashtags: 'coursepal,sociallearning,learningnetwork',
        twitterUser: 'coursepal'
      },
      networks: [
        { network: 'facebook', name: 'Facebook', icon: 'FacebookIcon', color: '#1877f2' },
        { network: 'linkedin', name: 'LinkedIn', icon: 'LinkedinIcon', color: '#007bb5' },
        { network: 'twitter', name: 'Twitter', icon: 'TwitterIcon', color: '#1da1f2' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'MessageCircleIcon', color: '#25d366' },
      ],
      selected: { id: 1, label: "5 stars" },
			courseFeed: {},
      isCourseFeedSubscribedByUser : false,    
      courseFeedChannel : {},
      title:'Coursepal, Social Learning App',
      description:'Coursepal helps you learn, network, and find job opportunities.',
      image:''
    };
  },
  components: {
    "v-select": vSelect,
    ReviewPopUp,

    VueSocialSharing,
    StripeElementCard
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.auth.userData
    },
  },
  // metaInfo() {
  //       return {
  //           title: this.title,
  //           meta: [
  //                 {property: 'og:title', content: this.title},
  //                 {property: 'og:description', content: this.description},
  //                 {property: 'og:image', content: this.image},
  //           ]
  //       };
  //   },
	methods: {
     submit () {
      if(this.paymentMethod == 'newcard'){
        this.$refs.stripeRef.submit();
      }else{
        this.joinCourseFeedPrivateStripe();
      }
    },
     tokenCreated (token) {
      this.token = token;
      this.joinCourseFeedPrivateStripe();
    }, 
    bindUrl (value) {
      if (value.isPartner === true) {
       // return `partner/${value.name}`
        this.$router.push(`/partner/${value.name}`)
      } else {
      //  return `/profile/${value.name}`
       this.$router.push(`/profile/${value.name}`)
      }
    },
    async joinCourseFeedPrivateStripe() { 
      console.log(this.courseFeed);
      API.post('cpRestAPI', '/coursefeedprivate/join', {
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        amount:this.getTotal(),
        token: this.token,
        description: "Join course feed",
        full_name: this.activeUserInfo.name,
        email: this.activeUserInfo.email,
        stripeAccountKey: this.activeUserInfo.stripeAccountKey,
        paymentMethod:this.paymentMethod,
        application_fee_amount : this.getApplicationFeeAmount(),
        connected_stripe_account_id : this.courseFeed.userRef.stripeConnectedAccountKey,
        // connected_stripe_account_id :'acct_1JMQrvRYHoQsC6hA',
        input: {
          courseFeed: this.courseFeed.id,
          user: this.$store.state.auth.userData.name
        },
    }}).then(res => {   
      if(res.success == true){
        let stripeChargeId = res.response.id;
        this.joinCourseFeedPrivate(stripeChargeId);        
      }else{       
        if(res.response){
          if(res.response.raw){
            this.$vs.notify(createErrorObject('Error', res.response.raw.message))
          }
        }
      }      
    })
    .catch(err => {
        console.log('joinCourseFeed', err)
    })
    },
    async joinCourseFeedPrivate (stripeChargeId) {
			try {
				const res = await API.graphql(graphqlOperation(createCourseFeedSubscriber, {
          input: {
            courseFeed: this.courseFeed.id,
            user: this.$store.state.auth.userData.name,
            stripeChargeId : stripeChargeId
          }
        }))
        this.$vs.notify(createSuccessObject('Success', 'Great work, You have joined this group now!'))
        this.booknowCourse = false;
			} catch (err) {
				console.log('joinCourseFeed', err)
			}
		},
    getGst(price) {
      let gst = (parseFloat(price ) / 11).toFixed(2);
      return Number(gst);
    }, 
    getDiscount(price,discountPrice) { 
      let amount = 0;
      if(Number(this.courseFeed.discountPrice) == 0 || this.courseFeed.discountPrice == null){
        amount =  0
      }else{
        amount =  (parseFloat(price).toFixed(2)) - (parseFloat(discountPrice).toFixed(2))
      }      
      return Number(amount)
    },
    getApplicationFeeAmount() { 
      let application_fee_amount = parseFloat(this.getTotal() * 0.15).toFixed(2);
      return Number(application_fee_amount)
    }, 
    getTotal() {
      let amount = 0;
      if(Number(this.courseFeed.discountPrice) == 0 || this.courseFeed.discountPrice == null){
        amount = parseFloat(this.courseFeed.price).toFixed(2);
      }else{
        amount =  parseFloat(this.courseFeed.discountPrice).toFixed(2);
      }      
      return Number(amount);
    },
    getTotalPrice(price) {
      let amount = parseFloat(price).toFixed(2);    
      return Number(amount)
    }, 
    async customerListsources() { 
      API.post('cpRestAPI', '/stripeaccount/customer/listsources', {
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        stripeAccountKey: this.activeUserInfo.stripeAccountKey,
      }}).then(res => {
        if(res.success == true){
          this.card_list = res.response.data;
          this.paymentMethod = this.card_list[0].id;
        }else{
          this.card_list = [];
          this.paymentMethod = 'newcard';
        }
      })
      .catch(err => {
          this.card_list = [];
      })
    },
    getHtmlValue(val) {
      var div = document.createElement("div");
      div.innerHTML = val;
      // console.log(div.innerHTML);
      return div.innerHTML.replace(/<\/?[^>]+(>|$)/g, "");
    },
		async fetchCourseDetail () {
			try {
        this.$vs.loading()
				const res = await API.graphql(graphqlOperation(getCourseFeed, {
					id: this.$route.params.id
				})) 
				if (res.data.getCourseFeed.image) {
					const imageURL = await Storage.get(res.data.getCourseFeed.image)
					res.data.getCourseFeed.imageURL = imageURL
				}
 
				this.courseFeed = res.data.getCourseFeed
        this.title = this.courseFeed.title;
        this.description = this.courseFeed.description;
        this.image = this.courseFeed.imageURL;
        //this.url=+`/course-details/${getCourseFeed.id}`
        this.url =`https://coursepal.app/course-details/${this.courseFeed.id}`

        try {
            const findRes = await API.graphql(graphqlOperation(courseFeedSubscriptionByUser, {
              user: this.$store.state.auth.userData.name,
              courseFeed: {
                eq: this.courseFeed.id
              }
            }))
            if (findRes.data.courseFeedSubscriptionByUser.items.length !== 0) { 
              this.isCourseFeedSubscribedByUser = true;    
              this.courseFeedChannel = findRes.data.courseFeedSubscriptionByUser.items[0]; 
            }
          
        } catch (err) {
          console.log('joinCourseFeed', err)
        } 
        this.$vs.loading.close()
			} catch (err) {
				console.log('fetchCourseDetail', err)
			}
		},
    goToChatRoom () {
      this.$router.push(`/chat/${this.courseFeed.user}-${this.$store.state.auth.userData.name}`)
    },
		async joinCourseFeed () {
			try {
          const findRes = await API.graphql(graphqlOperation(courseFeedSubscriptionByUser, {
            user: this.$store.state.auth.userData.name,
            courseFeed: {
              eq: this.courseFeed.id
            }
          }))
          if (findRes.data.courseFeedSubscriptionByUser.items.length === 0) {            
            if (this.courseFeed.type === 'Private') {
              this.booknowCourse = true
            } else {
                const res = await API.graphql(graphqlOperation(createCourseFeedSubscriber, {
                  input: {
                    courseFeed: this.courseFeed.id,
                    user: this.$store.state.auth.userData.name
                  }
                }))
                this.sendNewUserJoinedMailToEducator();
                this.sendNewUserJoinedMailToUser();
                this.$vs.notify(createSuccessObject('Success', 'You have joined this coursefeed'))
            }
          } else {
             this.sendNewUserJoinedMailToEducator();
                this.sendNewUserJoinedMailToUser();
            this.$vs.notify(createErrorObject('Error', 'You have already joined this group.'))
          }
				
			} catch (err) {
				console.log('joinCourseFeed', err)
			}
		},
    visitCourseFeed(){
      this.$store.dispatch('channel/switchChannel', {
        channel: this.courseFeedChannel,
        type: 'COURSE',
        router: this.$router,
        loader: this.$vs.loading        
      })
    },
    async sendNewUserJoinedMailToEducator() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();            
        urlencoded.append("template_code", 'new_user_joined');
        urlencoded.append("to", this.courseFeed.userRef.email);
        urlencoded.append("bcc", 'support@coursepal.app');
        urlencoded.append("username", this.$store.state.auth.userData.displayName);
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow"
        };   
        const rawResponse = await fetch(
          // process.env.VUE_APP_BASE_URL + "/api/sendTestMail",
          `${process.env.VUE_APP_BASE_URL}/api/sendAppMail`,
          // "https://api.coursepal.app/api/sendAppMail",
          // "http://localhost:7000/api/sendAppMail",
          requestOptions
        );  
        const response = await rawResponse.json();
        if (response.status == 1) {
        }                  
    },
    async sendNewUserJoinedMailToUser() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();            
        urlencoded.append("template_code", 'welcome_coursefeed');
        urlencoded.append("to", this.$store.state.auth.userData.email);
        urlencoded.append("bcc", 'support@coursepal.app');
        urlencoded.append("username", this.$store.state.auth.userData.displayName);
        urlencoded.append("coursefeed_title", this.courseFeed.title);
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow"
        };   
        const rawResponse = await fetch(
          // process.env.VUE_APP_BASE_URL + "/api/sendTestMail",
          `${process.env.VUE_APP_BASE_URL}/api/sendAppMail`,
          // "https://api.coursepal.app/api/sendAppMail",
          // "http://localhost:7000/api/sendAppMail",
          requestOptions
        );  
        const response = await rawResponse.json();
        if (response.status == 1) {
        }                  
    },
	},
  mounted() {
    this.$store.commit("TOGGLE_REDUCE_BUTTON", true);
  },
	created () {
		this.fetchCourseDetail()
    this.customerListsources();
	}
};
</script>

<style lang="scss">
.bg-grey-light {
  // background: #f0f0f0 !important;
  // color: rgba(0, 0, 0, 0.7) !important;
  background: rgb(96, 155, 252) !important;
  color: #fff !important;
}
.bg-custom-light {
  margin-right: 5px !important;
  margin-bottom: 8px !important;
  font-size: 13px !important;
  line-height: 1.1 !important;
  padding: 4px 8px !important;
  // font-weight: 600 !important;
  color: rgb(53, 64, 83) !important;
  background-color: rgb(228, 234, 240) !important;
  border-radius: 4px !important;
  word-break: break-word;
}
.share-network-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    margin: auto;
  }
  a[class^="share-network-"] {
    flex: none;
    color: #FFFFFF;
    background-color: #333;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 10px 10px 0;
    margin: 0 10px 0px 0;
  }
  
  // a[class^="share-network-"] .fah {
  //   background-color: rgba(0, 0, 0, 0.2);
  //   padding: 10px;
  //   flex: 0 1 auto;
  // }
  a[class^="share-network-"] .feather-icon {
    // background-color: rgba(0, 0, 0, 0.2);
    // padding: 10px;
    padding: 5px;
    flex: 0 1 auto;
  }
  a[class^="share-network-"] span {
    padding: 0 10px;
    flex: 1 1 0%;
    font-weight: 500;
  }
  @media (min-width: 992px) {
  .cd-image{
  height:420px;
  -o-object-fit: cover;
  object-fit: cover;
}
  }
</style>