  <template>
      <div class="px-4 py-4">
              <!-- <h4>Write a review</h4> -->
               <div class="vx-col w-full mt-4">
              <label class="">Choose rating</label>
              <v-select v-model="selected" :options="options" :dir="$vs.rtl ? 'rtl' : 'ltr'" /><br>
              </div>
              <div class="vx-col w-full">
              <vs-textarea label="Add your comment" v-model="textarea" />
              </div>
             
              <div class="vx-col mt-3 text-right">
              <vs-button color="primary" type="filled">Submit Review</vs-button>
              </div>
            </div>
  </template>

<script>
import vSelect from 'vue-select'

export default {
 
  data () {
    return {
      
      textarea:'',
      options: [
        {id: 1, label: '5 stars'},
        {id: 2, label: '4 stars'},
        {id: 3, label: '3 stars'},
        {id: 4, label: '2 stars'},
        {id: 5, label: '1 star'}
      ],
      selected: {id: 1, label: '5 stars'}
    }
  },
  components: {
    'v-select': vSelect
  },
  computed:{
  },
  created () {
  
  }, 
  methods:{
    
  }
}
</script>